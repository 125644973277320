<template>
  <div>
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-3 card']">
      <v-container fluid>
        <v-layout>
          <v-flex xs12 lg6>
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field outlined
                              v-model="fromDate"
                              :label="$t('generic.lang_from')"
                              hint="dd/mm/yyyy Format"
                              persistent-hint
                              prepend-icon="event"
                              @blur="date1 = parseDate(fromDate)"
                              v-on="on"
                              locale="de"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date1" locale="de" no-title @input="menu1 = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 lg6>
            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field outlined
                              v-model="tillDate"
                              :label="$t('generic.lang_to')"
                              hint="dd/mm/yyyy Format"
                              persistent-hint
                              prepend-icon="event"
                              @blur="date2 = parseDate(tillDate)"
                              v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date2" locale="de" no-title @input="menu2 = false"></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>

      </v-container>
    </div>

    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-3 card']">
      <v-container grid-list-md fluid>
        <v-layout row wrap>
          <v-flex xs12 lg12>
            <table style="clear:both;" :class="[this.$vuetify.theme.dark? 'dark-bg table-light table table-striped table-bordered' : 'table-light table table-striped table-bordered table-hover']">
              <col/>
              <thead>
              <tr>
                <th scope="col" class="text-capitalize">{{ $t('generic.lang_filename') }}</th>
                <th scope="col" class="text-capitalize">{{ $t('generic.lang_fileDescription') }}</th>
                <th scope="col" class="text-uppercase">{{ $t('generic.lang_download') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>INDEX.XML Datei</td>
                <td>DSFIN-V K Index XML Beschreibungsdatei</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="getDSFINVKindex()">{{ $t('generic.lang_download') }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>gdpdu-01-09-2004.dtd</td>
                <td>gdpdu-01-09-2004.dtd Beschreibungsdatei</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="getDSFINVKdtd()">{{ $t('generic.lang_download') }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td><h5 class="text-capitalize">{{ $t('settings.lang_singleRecordingModule') }}</h5></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>tse_transactions</td>
                <td></td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/TSE_Transaktionen')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>transactions</td>
                <td>Kumulierte Bonwerte.</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonkopf')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>datapayment</td>
                <td></td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonkopf_Zahlarten')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>lines</td>
                <td>Die Datei Bonpos enthält die einzelnen Positionen eines Vorgangs mit der Zuordnung
                  des korrekten USt-Satzes, der Menge und der Art der gelieferten Gegenstände.
                </td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonpos')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>

              <tr>
                <td>itemamounts</td>
                <td>Entstehung der Verkaufspreise</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonpos_Preisfindung')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>subitems</td>
                <td>Zusatzinfos</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonpos_Zusatzinfo')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>transactions_vat</td>
                <td></td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonkopf_USt')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>lines_vat</td>
                <td></td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonpos_USt')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>allocation_groups</td>
                <td></td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bonkopf_AbrKreis')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>references</td>
                <td></td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Einzelaufzeichnungsmodul/Bon_Referenzen')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>

              <tr>
                <td><h5 class="text-capitalize">{{ $t('settings.lang_masterDataModule') }}</h5></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>cashpoint_closing</td>
                <td>Daten des Kassenabschlusses, dazu gehören Datum, Uhrzeit und Start- sowie End-ID.
                  Ebenfalls werden die Unternehmensdaten inkl. Steuernummer bzw. Umsatzsteuer-
                  Identifikationsnummer hier gespeichert.
                </td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Stammdatenmodul/Stamm_Abschluss')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>location</td>
                <td>Namen und Orte der einzelnen Betriebsstätten mit Kassen.</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Stammdatenmodul/Stamm_Orte')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>cashregister</td>
                <td>Stammdaten der einzelnen eingesetzten Kassen.</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Stammdatenmodul/Stamm_Kassen')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>slaves</td>
                <td>Stammdaten der einzelnen Erfassungs-Terminals (sog. Slave-Kassen), über die nicht
                  der Kassenabschluss erfolgt.
                </td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Stammdatenmodul/Stamm_Terminals')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>pa</td>
                <td>Werden Beträge „für Rechnung Dritter“ erfasst (durchlaufende Posten), ist der Dritte
                  verantwortlich für die korrekte Erfassung der Umsatzsteuer (z. B. Shop-in-Shop, wobei
                  es unabhängige Unternehmer sein müssen).
                </td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Stammdatenmodul/Stamm_Agenturen')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>tse</td>
                <td>Stammdaten der genutzten technischen Sicherheitseinrichtungen.</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Stammdatenmodul/Stamm_TSE')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>vat</td>
                <td>Stammdaten zur Umsatzsteuer.</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Stammdatenmodul/Stamm_USt')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td><h5 class="text-capitalize">{{ $t('settings.lang_cashRegisterModule') }}</h5></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>business cases</td>
                <td>Liste aller Geschäftsvorfalltypen</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Kassenabschlussmodul/Z_GV_Typ')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>payment</td>
                <td>Liste der Zahlarttypen und Zahlarten</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Kassenabschlussmodul/Z_Zahlart')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>cash_per_currency</td>
                <td>Liste aller Währungen</td>
                <td>
                  <v-btn color="primary" :disabled="fromDate.length < 1"
                         @click="displayDSFINVKReport('Kassenabschlussmodul/Z_Waehrungen')">{{
                      $t('generic.lang_download')
                    }}
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </table>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>


<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import {mapState} from "vuex";

export default {
  name: "DsfinvkExport",

  data: vm => ({
    date1: new Date().toISOString().substr(0, 10),
    date2: new Date().toISOString().substr(0, 10),
    tillDate: vm.formatDate(new Date().toISOString().substr(0, 10)),
    fromDate: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    menu2: false
  }),


  watch: {
    date1(val) {
      this.fromDate = this.formatDate(this.date1);
    },
    date2(val) {
      this.tillDate = this.formatDate(this.date2);
    }
  },

  computed: {
    ...mapState([
      'api'
    ])
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    toTimestamp(strDate) {

      if (!strDate) return null

      const [day, month, year] = strDate.split('/')

      var datum = Date.parse(`${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`);
      return datum / 1000;
    },
    displayDSFINVKReport: function (load) {

      let tsTimeStart = this.toTimestamp(this.fromDate + " 00:00:00");
      let tsTimeEnde = this.toTimestamp(this.tillDate + " 23:59:59");

      let apiServerIP = process.env.VUE_APP_API_HOST;
      if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
       let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.open(apiServerIP + "/get/fiscal/de_dsfinvk/" + load + ".php?download=1&fromTime=" + tsTimeStart + "&toTime=" + tsTimeEnde + "&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");

    },
    getDSFINVKindex: function () {
      let apiServerIP = process.env.VUE_APP_API_HOST;
      if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
        let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.open(apiServerIP + "/get/fiscal/de_dsfinvk/export/index_xml.php", "_blank");

    },
    getDSFINVKdtd: function () {
      let apiServerIP = process.env.VUE_APP_API_HOST;
      if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
        let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.open(apiServerIP + "/get/fiscal/de_dsfinvk/export/gdpdu-01-09-2004.dtd", "_blank");

    }
  }
}
</script>