<template>

    <div>
        <page-title :heading=heading :subheading="$t('settings.lang_exportInterfaceForDsfinvkExport')" :icon=icon></page-title>
        <div class="app-main__inner">
                <DsfinvkExport></DsfinvkExport>
            </div>
    </div>


</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import DsfinvkExport from "../../../components/settings/fiscal_de/Export";

    export default {
        components: {
            PageTitle,
            DsfinvkExport
        },

        data: () => ({
            heading: 'DSFinV-K',
            icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
        }),
    }
</script>